/*
SCREENSIZE
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 25, 2022
UPDATE DATE: May 25, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ScreenSize | Check your screen size!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .05);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  // box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > a{
    display: block;
    font-size: 20px;
    line-height: 40px;
    font-weight: bold;
    width: 185px;
    margin: 0 auto;
    color: #FFF;

    &:hover{
      color: #FFF;
    }

    > svg{
      display: block;
      width: 100%;
      height: 40px;
    }
  }

  &.dark{
    background: rgba(0, 0, 0, .05);

    > a{
      color: #000;

      &:hover{
        color: #000;
      }
    }
  }
}

ul.set{
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, .25);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  padding: 15px 15px 20px 15px;
  width: 20%;
  box-sizing: border-box;
  z-index: 1;

  > li{
    padding: 5px;
    font-size: 14px;
    line-height: 18px;
    color: #FFF;

    > ul{
      overflow: auto;

      > li{
        float: left;
        width: 50%;

        &:last-child{
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }
}

.rc-slider{
  .rc-slider-rail{
    height: 12px;
    border-radius: 3px;
    background: #EEE;
    border: 1px solid rgba(255, 255, 255, 1);
    overflow: hidden;
  }

  .rc-slider-track{
    height: 10px;
    margin: 1px 0 0 1px;
    border-radius: 3px;
    background: #666;
  }

  .rc-slider-handle{
    width: 22px;
    height: 22px;
    border-radius: 5px;
    border: 2px solid #FFF;
    // border: none;
    background: transparent;
    opacity: 1;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, .4);
    transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;
    -moz-transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;
    -webkit-transition: background 200ms ease-in-out, box-shadow 200ms ease-in-out;

    &.rc-slider-handle-dragging{
      box-shadow: none;
      // border: none;
    }

    &:focus-visible{
      border: 2px solid #FFF;
      box-shadow: none;
    }

    &:hover{
      border: 2px solid #FFF;
      box-shadow: 0 0 10px rgba(255, 255, 255, 1);
    }

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 2px;
      height: 100%;
      background: #FFF;
    }
  }
  
  &.hue{
    .rc-slider-rail{
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,ffff00+17,00ff00+33,00ffff+50,0000ff+67,ff00ff+83,ff0000+100 */
      background: rgb(255,0,0); /* Old browsers */
      background: -moz-linear-gradient(left,  rgba(255,0,0,1) 0%, rgba(255,255,0,1) 17%, rgba(0,255,0,1) 33%, rgba(0,255,255,1) 50%, rgba(0,0,255,1) 67%, rgba(255,0,255,1) 83%, rgba(255,0,0,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 */
    }
  }

  &.alp{
    .rc-slider-rail{
      &:before{
        content: '';
        opacity: .1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(./assets/img/bg.png) center center repeat;
        background-size: 9px 9px;
      }
    }
  }

  &.trans{
    .rc-slider-track{
      background: transparent;
    }
  }
}

.con-bg{
  background: url(./assets/img/bg.png) center center repeat;
  background-size: 30px 30px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  .con-color{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  color: #FFF;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  a{
    font-weight: bold;
    color: #FFF;

    &:hover{
      margin: 0 5px;
      color: #FFF;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }

  &.dark{
    color: #000;

    > a{
      color: #000;

      &:hover{
        color: #000;
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  ul.set{
    width: 30%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  ul.set{
    width: 40%;
  }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  ul.set{
    width: 65%;

    > li{
      width: 50%;
      float: left;
      box-sizing: border-box;

      &:nth-child(1){
        width: 100%;
      }
    }
  }

  footer{
    padding: 20px 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  header{
    > ul{
      > li{
        width: 100%;

        &:nth-child(1){
          > a{
            > svg{
              width: 113px;
            }
          }

          > ul{
            margin: -5px -5px -5px 123px;
          }
        }
      }
    }
  }

  ul.set{
    width: 70%;
  }

  footer{
    padding: 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/